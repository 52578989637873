import axios from 'axios';
import os from 'os';

let instance = axios.create({
    // `baseURL` will be prepended to `url` unless `url` is absolute.
    baseURL: ~os.hostname().search('localhost|127.0.0.1')
        ? '//localhost:1919'
        : '//www.sureshkm.com',

    // `timeout` specifies the number of milliseconds before the request times out.
    timeout: 8000,

    // `transformRequest` allows changes to the request data before it is sent to the server
    transformRequest: [
        function(data) {
            return data;
        },
    ],

    // `transformResponse` allows changes to the response data to be made before
    transformResponse: [
        function(data) {
            return data;
        },
    ],

    // `maxContentLength` defines the max size (bytes) of the http response content allowed
    maxContentLength: 10000,

    // `responseType` indicates the type of data that the server will respond with
    // options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    responseType: 'json', // default
});

export { instance as webservice };
