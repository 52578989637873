import styled from 'styled-components';
import { ListGroup as defListGroup } from 'react-bootstrap';
import { Constants } from '../';

export const ListGroup = styled(defListGroup)``;
export const ListGroupHorizontal = styled(defListGroup)`
    padding: 0;

    .list-group-item {
        border: 0;
        border-radius: 0;
        display: block;
        padding: 0 10px;
    }

    .list-group-item:first-child,
    .list-group-item:last-child {
        border: 0;
    }

    .list-group-item:first-child {
        padding-left: 0;
    }

    ${Constants.media.sm} {
        .list-group-item {
            border-right: 1px solid #000;
            display: inline-block;
        }
    }
`;
