import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Button, CardDeck } from './../../_styles';
import { PinsLoader } from './_loaders';
import { Pin } from './Pin';

export class Pins_ extends Component {
	constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        };
		this._isMounted = false;
        this.pinsList = [];
        this.pins = [];
        this.apiResultsPageNumber = 1;
        this.apiResultsSize = 8;
        this.apiResultsStartNumber = 0;
        this.apiResultsEndNumber = (this.apiResultsSize * this.apiResultsPageNumber) - 1;
        this.onLoadMore = this.onLoadMore.bind(this);
    }

    componentDidMount() {
    	this._isMounted = true;
        this.props.startLoadingPins(this.props.match.params.id, this.apiResultsStartNumber + '', this.apiResultsEndNumber + '').then(() => {
			if (this._isMounted) {
				this.setState({
					isLoaded: true,
                    pins: this.props.pins
				});
                this.createPinsList();
			}
        });
    }

	componentWillUnmount() {
    	this._isMounted = false;
  	}

    onLoadMore() {
        this.apiResultsStartNumber = (this.apiResultsSize * this.apiResultsPageNumber);
        this.apiResultsEndNumber = this.apiResultsStartNumber + this.apiResultsSize - 1;
        this.apiResultsPageNumber++;
        this.callAPI(true);
    }

    callAPI(isLoadingMore) {
        this.props.startLoadingPins(this.props.match.params.id, this.apiResultsStartNumber + '', this.apiResultsEndNumber + '').then(() => {
            this.setState({
                pins: this.props.pins
            });
            this.createPinsList(true);
        });
    }

    createPinsList(isLoadingMore) {
		 if (!isLoadingMore) {
			this.setState({
				pinsList: [],
			});
		}
        this.setState({
            pinsList: this.state.pinsList.concat(
                this.state.pins.map((pin, index) => (
                    <Pin data={ pin } key={ pin.id } /> 
                ))
            )
        })
    }

    render() {
		if (this.state.isLoaded) {
			return (
                <div>
                    <Row spacery="true" justifycenter="true">
                        { this.state.pinsList }
                    </Row>
                    { !!this.state.pins.length && (
                        <Row spacery="true" justifycenter="true">
                            <Button variant="primary" onClick={ this.onLoadMore }>
                                Load more ...
                            </Button>
                        </Row>
                    ) }
                </div>
			);
		} else {
			return <PinsLoader />;
		}
    }
}

Pins_.propTypes = {
	startLoadingPins: PropTypes.func
};

const mapStateToProps = (state) => ({ pins: state.pins });
export const Pins = connect(mapStateToProps)(Pins_);
