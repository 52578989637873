import { boardConstants } from '../../../_constants';

export function boards(state = [], action) {
    switch (action.type) {
        case boardConstants.ADD:
            return [...state, action.board];
        case boardConstants.REMOVE:
            return [
                ...state.slice(0, action.index),
                ...state.slice(action.index + 1),
            ];
        case boardConstants.LOAD:
            return action.boards;
        default:
            return state;
    }
}
