import React, { Component } from 'react';
import { AddPin as AddPin_ } from './../../Components/AddPin';

export class AddPin extends Component {
    render() {
        return (
            <AddPin_ { ...this.props } />
        )
    }
}

