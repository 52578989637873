import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from './../../_styles';
import { BoardsLoader } from './_loaders';
import { Board } from './Board';

export class Boards_ extends Component {
	constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        };
		this._isMounted = false;
    }

    componentDidMount() {
    	this._isMounted = true;
        this.props.startLoadingBoards().then(() => {
			if (this._isMounted) {
				this.setState({
					isLoaded: true,
				});
			}
        });
    }

	componentWillUnmount() {
    	this._isMounted = false;
  	}

    render() {
		if (this.state.isLoaded) {
			return (
				<Row spacery="true">
                    {this.props.boards.map((board, index) => (
                        <Board data={ board } key={ index } /> 
                    ))}
				</Row>
			);
		} else {
			return <BoardsLoader />;
		}
    }
}

Boards_.propTypes = {
	startLoadingBoards: PropTypes.func
};

const mapStateToProps = (state) => ({ boards: state.boards });
export const Boards = connect(mapStateToProps)(Boards_);
