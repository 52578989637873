import React from 'react';
import ContentLoader from 'react-content-loader';
import { Theme } from '../../../_themes/SureMui';

export const HeaderLoader = () => {
    return (
        <ContentLoader
            height={ 15 }
            width={ 200 }
            speed={ 1 }
            primaryColor={ Theme.loader.strip.primaryColor }
            secondaryColor={ Theme.loader.strip.secondaryColor }
        >
            <rect x="0" y="2" rx="3" ry="3" width="22" height="10" /> 
            <rect x="28" y="2" rx="3" ry="3" width="170" height="10" />
        </ContentLoader>
    );
};
