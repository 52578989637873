// we would add all the common properties of this VZMui theme like font family, color etc
export const Theme = {
    font: {
        family: '\'OpenSans-Regular\'',
        color: '#000',
    },

    a: {
        color: '#000',
        underline: {
            borderColor: '#d52b1e',
        },
    },

    p: {
        sm: '0.875em',
        md: '1.2em',
    },

    heading: {
        font: {
            family: '\'NHaasGroteskDSW02-75Bd\',helvetica,sans-serif',
        },
    },

    loader: {
        strip: {
            primaryColor: '#f3f3f3',
            secondaryColor: '#ecebeb',
        },
    },
};
