import { pinsConstants } from '../../../_constants';

export function pins(state = [], action) {
    switch (action.type) {
		case pinsConstants.ADD:
			if (!state[action.boardId]) {
				return { ...state, [action.boardId]: [action.pin] };
			} else {
				return {
					...state,
					[action.boardId]: [...state[action.boardId], action.pin],
				};
			}
        case pinsConstants.LOAD:
            return action.pins;
        default:
            return state;
    }
}
