import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

var config = {
    apiKey: 'AIzaSyBoeGPcnmyGafoK9yxP5AMdBc5an1JaRys',
    authDomain: 'sureshkm-d65f1.firebaseapp.com',
    databaseURL: 'https://sureshkm-d65f1.firebaseio.com',
    projectId: 'sureshkm-d65f1',
    storageBucket: 'sureshkm-d65f1.appspot.com',
    messagingSenderId: '118411327334',
};
firebase.initializeApp(config);

const database = firebase.database();
const auth = firebase.auth;

export { database, auth };
