// Extra small devices (portrait phones, less than 576px)

// No media query for `xs` since this is the default in Bootstrap

export const Constants = {
    media: {
        // Small devices (landscape phones, 576px and up)
        sm: '@media (min-width: 576px)',

        // Medium devices (tablets, 768px and up)
        md: '@media (min-width: 768px)',

        // Large devices (desktops, 992px and up)
        lg: '@media (min-width: 992px)',

        // Extra large devices (large desktops, 1200px and up)
        xl: '@media (min-width: 1200px)',
    },
};
