import { database } from '../../../_database/config';
import { boardConstants } from '../../../_constants';

export function startLoadingBoards() {
    return (dispatch) => {
        return database
            .ref('boards')
            .orderByChild('name')
            .once('value')
            .then((snapshot) => {
                let boards = [];
                snapshot.forEach((childSnapshot) => {
                    boards.push(childSnapshot.val());
                });
                dispatch(loadBoards(boards));
            });
    };
}

export function loadBoards(boards) {
    return {
        type: boardConstants.LOAD,
        boards,
    };
}

export function startAddingBoard(board) {
    return (dispatch) => {
        return database
            .ref('boards')
            .update({ [board.id]: board })
            .then(() => {
                dispatch(addBoard(board));
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function addBoard(board) {
    return {
        type: boardConstants.ADD,
        board,
    };
}

