import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from './../../_styles';
import { Boards } from './../../Components/Boards';

export class Home_ extends Component {

    render() {
        return (
            <Row>
                <Col>
                    <Boards { ...this.props } />
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({ header: state.header });
export const Home = connect(mapStateToProps)(Home_);
