import { createGlobalStyle } from 'styled-components';
import { Constants } from './../../_styles';

export const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'Dosis';
        font-weight: 400;
        font-style: normal;
        src: url("/fonts/Dosis-Regular/Dosis-Regular.ttf") format("truetype"),
            url("/fonts/Dosis-Regular/Dosis-Regular.woff2") format("woff2"),
            url("/fonts/Dosis-Regular/Dosis-Regular.eot") format("embedded-opentype");
    }

    @font-face {
        font-family: 'OpenSans-Regular';
        font-weight: 400;
        font-style: normal;
        src: url("/fonts/OpenSans-Regular/OpenSans-Regular.ttf") format("truetype"),
            url("/fonts/OpenSans-Regular/OpenSans-Regular.woff2") format("woff2"),
            url("/fonts/OpenSans-Regular/OpenSans-Regular.eot") format("embedded-opentype");
    }
   
    body {
		color: ${(props) => props.theme.font.color};
        font-family: ${(props) => props.theme.font.family};
        font-size: 18px;
         // Fix me: need to find out the solution for 12 cols in safari browser
         // bootstrap grid safrai fix
         @media screen and (-webkit-min-device-pixel-ratio:0) {
             .row:before, .row:after {display: none;}
         }
    }

  	a,
  	a:hover,
    a:focus {
  		color: ${(props) => props.theme.a.color};
  		text-decoration: none;
    }

    p {
        font-size: 0.875em;
    }

    h1 {
        font-size: 2.1em;
        font-family: ${(props) => props.theme.heading.font.family};
        font-weight: bold;
        margin: 0;
        margin-bottom: 25px;
        padding: 0;
    }    

    h2 {
        font-family: ${(props) => props.theme.heading.font.family};
        font-size: 1.4em;
        font-weight: bold;
    }

    h4 {
        font-size: 1.25em;
        font-weight: 700;
        font-family: ${(props) => props.theme.heading.font.family};
    }

    ${Constants.media.md} {
        h1 {
          font-size: 3em;
          line-height: 1;
        }

        h2{
            font-size: 1.5em
        }
    }

    ${Constants.media.lg} {
        h1 {
          font-size: 4em;
        }
        h2{
            font-size: 2em;
        }
    }

    hr {
      display: block;
      border: 1px solid #000;
    }

    .container {
        max-width: 1298px;
    }
}`;
