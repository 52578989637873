import { wsClient } from '../wsClient';
const client = new wsClient();

// all the common API calls like header, footer, nav etc
export default {
    // header module data API call
    header() {
        return client.get('/api/header.json');
    },
    footer() {
        return client.get('/api/footer.json');
    },
};
