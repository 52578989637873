import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as actions from '../../_redux/_actions';
import Main from '../../Pages/Main';

// map the required redux data (states) to the connected components props
const mapStateToProps = (state) => ({
    header: state.header,
    footer: state.footer,
});

// turns an object whose values are action creators, into an object with the same keys,
// every action creator wrapped into a dispatch call so they may be invoked directly
const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

// connects Main component to a Redux store
export const App = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Main),
);
