import { footerConstants } from './../../../_constants';

export function footer(state = [], action) {
    switch (action.type) {
    case footerConstants.LOAD:
        return action.footer;
    default:
        return state;
    }
}
