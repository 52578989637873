import React, { Component } from 'react';
import { Login as Login_ } from './../../Components/Login';

export class Login extends Component {
    render() {
        return (
            <Login_ { ...this.props } />
        )
    }
}

