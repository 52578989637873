import styled from 'styled-components';

export const CSSBoard = styled.div`
    .card-header {
        background: #f5f5f5;
        color: #000;
        font-weight: bold;
    }

    .card-header:hover {
        color: #8308f5;
    }

    .card-img {
        width: 15%;
        margin-right: 1rem;
    }

    .card-link:hover {
        color: #8308f5;
    }

    .card-link {
        font-weight: bold;
    }
`;

