import React, { Component } from 'react';
import Moment from 'react-moment';
import { Row, Col, Card } from './../../_styles';
import { CSSPin } from './_styles';

export class Pin extends Component {
    render() {
        let pin = this.props.data;
        return (
            <Col lg={6}>
                <Card>
                    <CSSPin>
                        <Card.Body> 
                            <a href={pin.url} target="_blank" rel="noopener noreferrer">
                                <Row>
                                    <Col lg={ 2 }>
                                        <img src={ pin.image } width="100%" />
                                    </Col>
                                    <Col lg={ 10 }>
                                        { pin.description }
                                    </Col>
                                </Row>
                            </a>
                            {/* 
                                <small className="text-muted">
                                   <Moment fromNow>{pin.timestamp}</Moment> 
                                </small>
                            */}
                        </Card.Body>
                    </CSSPin>
                </Card>
            </Col>
        );
    }
}
