import React from 'react';
import { Route, Redirect } from 'react-router-dom';
const ProtectedRoute = ({
    component: Component,
    authenticated,
    loading,
    ...rest
}) => {
    return (
        <Route
            authenticated={authenticated}
            {...rest}
            render={(params) =>
                authenticated ? (
                    <Component {...rest} {...params} />
                ) : (
                    <Redirect to="/authentication/login" />
                )
            }
        />
    );
};

export default ProtectedRoute;
