import React, { Component } from 'react';
import { Col, Card } from './../../_styles';
import { CSSBoard } from './_styles';

export class Board extends Component {
    render() {
        let board = this.props.data;
        return (
            <Col xs={ 12 } sm={ 3 } md={ 3 } lg={ 3 }>
                <CSSBoard>
                        <Card>
                            <Card.Link href={ board.wiki} target="_blank" rel="noopener noreferrer">
                                <Card.Header>
                                    <Card.Img src={ board.image }></Card.Img>
                                    { board.name }
                                </Card.Header>
                            </Card.Link>
                            <Card.Body>
                                <Card.Text>
                                    { board.description }
                                    <Card.Link href={ board.wiki} target="_blank" rel="noopener noreferrer"> ...</Card.Link>
                                </Card.Text>
                                <Card.Link href={ "/pins/" + board.id }>
                                    Bookmarks ››
                                </Card.Link>
                            </Card.Body>
                        </Card>
                </CSSBoard>
            </Col>
        );
    }
}
