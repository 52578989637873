import { webservice } from './config';

class wsClient {
    constructor() {
        this.client = webservice;
    }

    get(url, conf = {}) {
        return this.client
            .get(url, conf)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
}

export { wsClient };
