import { headerConstants } from './../../../_constants';
import common from './../../../_webservice/apis/common';

export function startLoadingHeader() {
    return (dispatch) => {
        return common.header().then((res) => {
            dispatch(loadHeader(res.data));
        });
    };
}

export function loadHeader(header) {
    return {
        type: headerConstants.LOAD,
        header,
    };
}
