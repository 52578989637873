import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { auth } from '../../_database/config';
import { Button } from './../../_styles';

const logoutUser = () => {
    auth().signOut();
};

class Logout_ extends Component {
    render() {
        return (
            <Button variant="primary" onClick={logoutUser}>
                Logout
            </Button>
        );
    };
};
export const Logout = withRouter(Logout_);
