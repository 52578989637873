import { combineReducers } from 'redux';
import { header, footer } from './common';
import { boards } from './boards';
import { pins } from './pins';

// specifies how the application's state changes in response to actions sent to the store
const rootReducer = combineReducers({
    header,
    footer,
    boards,
    pins,
});

export default rootReducer;
