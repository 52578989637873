import React, { Component } from 'react';
import { Form, Button } from './../../_styles';

export class AddBoard extends Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        if (event.target.checkValidity()) {
            let name = event.target.elements.name.value,
                desc = event.target.elements.desc.value,
                image = event.target.elements.image.value;

            if (name && desc) {
                const board = {
                    id: Number(new Date()),
                    name: name,
                    description: desc,
                    image: image,
                    timestamp: Date.now(),
                };
                this.props.startAddingBoard(board);
                this.props.history.push('/');
            }
        }
    }

    render() {
        if (this.props.loading) {
            return <div> Loading ... </div>;
        } else {
            return (
				<Form onSubmit={ this.handleSubmit }>
					<Form.Group controlId="formBoardName">
						<Form.Label>Board name</Form.Label>
						<Form.Control name="name" type="text" placeholder="Enter board name" />
					</Form.Group>
					<Form.Group controlId="formBoardImage">
						<Form.Label>Board image</Form.Label>
						<Form.Control name="image" type="text" placeholder="Enter board image URL"  />
					</Form.Group>
					<Form.Group controlId="formBoardDesc">
						<Form.Label>Board description</Form.Label>
						<Form.Control name="desc" type="textarea" placeholder="Enter board description"  />
					</Form.Group>
				   <Button variant="primary" type="submit">
						Add board
				   </Button>
				</Form>
			);
        }
    }
}
