import { headerConstants } from './../../../_constants';

export function header(state = [], action) {
    switch (action.type) {
    case headerConstants.LOAD:
        return action.header;
    default:
        return state;
    }
}
