import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Button } from './../../_styles';
let fs = require('fs');

export class AddPin_ extends Component {

    handleSubmit(event) {
        event.preventDefault();
        if (event.target.checkValidity()) {
            let url = event.target.elements.url.value,
                desc = event.target.elements.desc.value,
                image = event.target.elements.image.value,
                boardId = event.target.elements.id.value;

            if (url && desc) {
                const pin = {
                    id: Number(new Date()),
                    url: url,
                    description: desc,
                    image: image,
                    timestamp: Date.now(),
                };

                const pins = this.props.pins || [];
                pins.unshift(pin);
                this.props.startAddingPin(pins, boardId);
                this.props.history.push(`/pins/${boardId}`);
            }
        }
    }

    handlePinsSubmit(event) {
        let content = 'ADD CONTENT'; 

        for (let i = 0; i < content.length; i++) {
        const pin = {
               id: Number(new Date()) + i,
               url: content[i].url,
               description: content[i].title,
               image: content[i].img,
               timestamp: Date.now(),
            };

            const pins = this.props.pins || [];
            pins.push(pin);
            this.props.startAddingPin(pins, 1546408457884)
          }
    }


    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePinsSubmit = this.handlePinsSubmit.bind(this);
        this.state = {
            isLoaded: false,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.startLoadingPins(this.props.match.params.id).then(() => {
            if (this._isMounted) {
                this.setState({
                    isLoaded: true,
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { match } = this.props;
        const id = Number(match.params.id);
         
        if (this.state.isLoaded) {
            console.log(this.props.pins);
            return (
				<Form onSubmit={ this.handleSubmit }>
                     <input type="hidden" name="id" value={id} />
					<Form.Group controlId="formPinName">
						<Form.Label>Pin URL</Form.Label>
						<Form.Control name="url" type="url" placeholder="Page URL" />
					</Form.Group>
					<Form.Group controlId="formPinImage">
						<Form.Label>Pin image</Form.Label>
						<Form.Control name="image" type="text" placeholder="Enter pin image URL"  />
					</Form.Group>
					<Form.Group controlId="formPinDesc">
						<Form.Label>Pin description</Form.Label>
						<Form.Control name="desc" type="textarea" placeholder="Enter pin description"  />
					</Form.Group>
				   <Button variant="primary" type="submit">
						Add Pin
				   </Button>
                </Form>
            );
        } else {
            return <div> Loading ... </div>;
        }
    }
}

AddPin_.propTypes = {
	startLoadingPins: PropTypes.func
};

const mapStateToProps = (state) => ({ pins: state.pins });
export const AddPin = connect(mapStateToProps)(AddPin_);
