import { database } from '../../../_database/config';
import { pinsConstants } from '../../../_constants';

export function startLoadingPins(boardID, start, end) {
    return (dispatch) => {
         let db = database.ref('pins/' + boardID).orderByKey();
            if (start) {
                db = db.startAt(start);
            }
            if (end) {
                db = db.endAt(end);
            }
            return db.once('value')
            .then((snapshot) => {
                let pins = [];
                snapshot.forEach((childSnapshot) => {
                    pins.push(childSnapshot.val());
                });
                dispatch(loadPins(pins));
            });
    };
}

export function loadPins(pins) {
    return {
        type: pinsConstants.LOAD,
        pins,
    };
}

export function startAddingPin(pin, boardId) {
    return (dispatch) => {
        return database
            .ref('pins')
            .update({ [boardId]: pin })
            .then(() => {
                dispatch(addPin(pin, boardId));
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function addPin(pin, boardId) {
    return {
        type: pinsConstants.ADD,
        pin,
        boardId,
    };
}
