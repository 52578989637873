import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from './../../_styles';
import { CSSHeader } from './_styles';
import { HeaderLoader } from './_loaders';

export class Header_ extends Component {
	constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        };
    }

    componentDidMount() {
        this.props.startLoadingHeader().then(() => {
            this.setState({
                isLoaded: true,
            });
        });
    }

    render() {
		if (this.state.isLoaded) {
			return (
				<Row>
					<Col>
                        <CSSHeader>
                            <a href="/">
                                <img src={ this.props.header.logo.url } alt={ this.props.header.logo.alt } />
                            </a>
                        </CSSHeader>
                    </Col>
				</Row>
			);
		} else {
			return <HeaderLoader />;
		}
    }
}

Header_.propTypes = {
	startLoadingHeader: PropTypes.func
};

const mapStateToProps = (state) => ({ header: state.header });
export const Header = connect(mapStateToProps)(Header_);
