import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import {
    ListBoards,
    AddBoard
} from './';
import ProtectedRoute from '../ProtectedRoute';

export class Main extends Component {
    render() {
        return (
            <div>
                <Route
                    exact
                    path="/boards"
                    component={ () => <ListBoards { ...this.props } /> }
                />
                <ProtectedRoute
                    exact
                    authenticated={this.props.authenticated}
                    path="/boards/add"
                    component={ AddBoard }
                    {...this.props}
                />
            </div>
        );
    }
}
