import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { auth } from '../../_database/config';
import { Form, Button } from './../../_styles';

class LoginPage extends Component {
    state = {
        email: '',
        password: '',
        error: null,
    };

    handledivChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const { email, password } = this.state;
        auth()
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
                this.props.history.push('/');
            })
            .catch((error) => {
                this.setState({ error: error });
            });
    };

    render() {
        const { email, password, error } = this.state;
        if ( !this.props.authenticated ) {
            return (
                <Form onSubmit={ this.handleSubmit }>
                    {error ? (
                        <Form.Text>
                            { error.message }
                        </Form.Text>
                    ) : null}
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control name="email" type="email" placeholder="Enter email" value={ email } onChange={ this.handledivChange } />
                        <Form.Text>
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control name="password" type="password" placeholder="Password" value={ password } onChange={ this.handledivChange } />
                    </Form.Group>
                   <Button variant="primary" type="submit">
                        Submit
                   </Button>
                </Form>
            );
       } else {
            return (
                <div>You are logged in.</div>
            )
       }
    }
}

export const Login = withRouter(LoginPage);
