import styled from 'styled-components';
import { ButtonToolbar as defButtonToolbar } from 'react-bootstrap';
import { Constants } from './../';

export const ButtonToolbar = styled(defButtonToolbar)`
    padding-top: 5px;
    padding-bottom: 10px;
    font-family: \'NHaasGroteskDSW02-75Bd\';
    margin-left: 15px;

    ${Constants.media.md} {
        margin-left: 0;
    }

    .btn {
        border-radius: 100px;
        height: 43px;
        padding: 4px 20px 30px 20px;
        font-size: 0.875em;
        line-height: 2.3;
        min-width: 140px;
        outline: none;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .btn-dark {
        background-color: #000;
        color: #fff;
        margin-right: 15px;
    }

    .btn-light {
        color: #000;
        background-color: transparent;
        border-color: #000;
    }
`;
