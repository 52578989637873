import { footerConstants } from './../../../_constants';
import common from './../../../_webservice/apis/common';

export function startLoadingFooter() {
    return (dispatch) => {
        return common.footer().then((res) => {
            dispatch(loadFooter(res.data));
        });
    };
}

export function loadFooter(footer) {
    return {
        type: footerConstants.LOAD,
        footer,
    };
}
