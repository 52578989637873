import React, { Component } from 'react';
import { Boards as Boards_ } from './../../Components/Boards';

export class ListBoards extends Component {
    render() {
        return (
            <Boards_ { ...this.props } />
        )
    }
}

