import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { matchPath } from 'react-router';

import {
    ListPins,
	AddPin
} from './';
import ProtectedRoute from '../ProtectedRoute';

export class Main extends Component {
    render() {
        const match = matchPath(this.props.history.location.pathname, {
            path: '/pins/:id',
            strict: false
        });
        return (
            <div>
                <Route
					exact
                    path="/pins/:id"
                    component={ () => <ListPins { ...this.props } match={ match} /> }
                />
                <ProtectedRoute
                    authenticated={this.props.authenticated}
                    path="/pins/:id/add"
                    component={ AddPin }
					match={ match }
                    {...this.props}
                />
            </div>
        );
    }
}
