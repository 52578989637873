import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { auth } from '../_database/config';
import { Container, Row, Col } from './../_styles';
import { GlobalStyle, Theme } from './../_themes/SureMui';
import { MainLoader } from './_loaders'

// modules
import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';

// pages
import { Home } from './Home';
import { Main as Boards } from './Boards';
import { Main as Pins } from './Pins';
import { Main as Authentication } from './Authentication';

class Main extends Component {
    state = {
        isLoaded: false,
        authenticated: false,
    };

    componentDidMount() {
        auth().onAuthStateChanged((authenticated) => {
            authenticated
                ? this.setState(() => ({
                      authenticated: true,
                      isLoaded: true
                  }))
                : this.setState(() => ({
                      authenticated: false,
                      isLoaded: true
                  }));
        });
    }

    render() {
        if (this.state.isLoaded) {
            return (
                <ThemeProvider theme={Theme}>
                    <Container>
                        <Row>
                            <Col>
                                <GlobalStyle />
                                <Header {...this.props} />
                                <Route
                                    exact
                                    path="/"
                                    component={() => <Home {...this.props} />}
                                />
                                <Route
                                    path="/boards"
                                    component={() => <Boards authenticated={this.state.authenticated} {...this.props} />}
                                />
                                <Route
                                    path="/pins"
                                    component={() => <Pins authenticated={this.state.authenticated} {...this.props} />}
                                />
                                <Route
                                    path="/authentication"
                                    component={() => (
                                        <Authentication authenticated={this.state.authenticated} {...this.props}/>
                                    )}
                                />

                                <Footer {...this.props} />
                            </Col>
                        </Row>
                    </Container>
                </ThemeProvider>
            );
        } else {
           return <MainLoader /> 
        }
    }
}

export default Main;
