import React, { Component } from 'react';
import { Logout as Logout_ } from './../../Components/Logout';

export class Logout extends Component {
    render() {
        return (
            <Logout_ { ...this.props } />
        )
    }
}

