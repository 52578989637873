import styled from 'styled-components';

export const CSSPin = styled.div`
    .card-body {
        font-size: 1rem;
    }

    .card-body:hover {
        background: #e8e6e8;
    }
`;

