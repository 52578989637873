import React from 'react';
import ContentLoader from 'react-content-loader';
import { Theme } from '../../_themes/SureMui';

export const MainLoader = () => {
    return (
        <ContentLoader
            height={ 200 }
            width={ 400 }
            speed={ 1 }
            primaryColor={ Theme.loader.strip.primaryColor }
            secondaryColor={ Theme.loader.strip.secondaryColor }
        >
			<circle cx="55" cy="1" r="1" /> 
            <circle cx="172" cy="52" r="1" /> 
            <circle cx="126" cy="5" r="1" /> 
            <circle cx="200" cy="110" r="43" />
        </ContentLoader>
    );
};
