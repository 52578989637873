import styled from 'styled-components';
import { Button as defButton } from 'react-bootstrap';

export const Button = styled(defButton)`
    &.btn-primary,
    &.btn-primary:focus {
        background: #8308f5;
        border: #8308f5;
    }
`;
