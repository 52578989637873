import React from 'react';
import ContentLoader from 'react-content-loader';
import { Theme } from '../../../_themes/SureMui';

export const BoardsLoader = () => {
    return (
        <ContentLoader
            height={ 20 }
            width={ 200 }
            speed={ 1 }
            primaryColor={ Theme.loader.strip.primaryColor }
            secondaryColor={ Theme.loader.strip.secondaryColor }
        >
            <rect x="0" y="0" rx="0" ry="0" width="40" height="40" />
            <rect x="50" y="0" rx="0" ry="0" width="40" height="40" />
            <rect x="100" y="0" rx="0" ry="0" width="40" height="40" />
            <rect x="150" y="0" rx="0" ry="0" width="40" height="40" />
        </ContentLoader>
    );
};
