import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import {
    Login,
    Logout,
} from './';
import ProtectedRoute from '../ProtectedRoute';

export class Main extends Component {
    render() {
        return (
            <div>
                <Route
                    exact
                    path="/(authentication|authentication/login)"
                    component={ () => <Login { ...this.props } /> }
                />
                <ProtectedRoute
                    exact
                    authenticated={this.props.authenticated}
                    path="/authentication/logout"
                    component={ Logout }
                    {...this.props}
                />
            </div>
        );
    }
}
