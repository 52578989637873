import React from 'react';
import ContentLoader from 'react-content-loader';
import { Theme } from '../../../_themes/SureMui';

export const PinsLoader = () => {
    return (
        <ContentLoader
            height={ 20 }
            width={ 200 }
            speed={ 1 }
            primaryColor={ Theme.loader.strip.primaryColor }
            secondaryColor={ Theme.loader.strip.secondaryColor }
        >
            <rect x="0" y="0" rx="0" ry="0" width="20" height="20" />
            <rect x="30" y="0" rx="0" ry="0" width="20" height="20" />
            <rect x="60" y="0" rx="0" ry="0" width="20" height="20" />
            <rect x="90" y="0" rx="0" ry="0" width="20" height="20" />
            <rect x="120" y="0" rx="0" ry="0" width="20" height="20" />
            <rect x="150" y="0" rx="0" ry="0" width="20" height="20" />
            <rect x="180" y="0" rx="0" ry="0" width="20" height="20" />
            <rect x="210" y="0" rx="0" ry="0" width="20" height="20" />
            <rect x="240" y="0" rx="0" ry="0" width="20" height="20" />
            <rect x="270" y="0" rx="0" ry="0" width="20" height="20" />
        </ContentLoader>
    );
};
