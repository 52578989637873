import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { history, store } from './_helpers';

// check  package.json for the bootstrap (example: 4.3.1) version
import 'bootstrap/dist/css/bootstrap.css';
import { App } from './Components/App';

ReactDOM.render(
    <Provider store={ store }>
        <Router history={ history }>
            <App />
        </Router>
    </Provider>,
    document.getElementById('sureshkm'),
);
