import styled from 'styled-components';
import { Row as defRow } from 'react-bootstrap';

export const Row = styled(defRow)`
    ${(props) => (props.justifycenter ? 'justify-content: center' : '')};
    ${(props) => (props.valigncenter ? 'align-items: center' : '')};
    ${(props) => (props.valignend ? 'align-items: flex-end' : '')};
    ${(props) => props.mb && 'margin-bottom: ' + props.mb + 'px'};
    ${(props) => props.spacery && 'margin-bottom: 20px'};
`;
