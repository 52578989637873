import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from './../../_styles';
import { CSSFooter } from './_styles';
import { FooterLoader } from './_loaders';

export class Footer_ extends Component {
	constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        };
    }

    componentDidMount() {
        this.props.startLoadingFooter().then(() => {
            this.setState({
                isLoaded: true,
            });
        });
    }


    render() {
		if (this.state.isLoaded) {
			return (
				<Col>
					<Row spacery="true">
                        <CSSFooter>
                            <div>{ this.props.footer.data.copy }</div>
                            <div>{ this.props.footer.data.desc }</div>
                        </CSSFooter>
					</Row>
				</Col>
			);
		} else {
			return <FooterLoader />;
		}
    }
}

Footer_.propTypes = {
    startLoadingFooter: PropTypes.func
};

const mapStateToProps = (state) => ({ footer: state.footer });
export const Footer = connect(mapStateToProps)(Footer_);
