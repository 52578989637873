import React, { Component } from 'react';
import { AddBoard as AddBoard_ } from './../../Components/AddBoard';

export class AddBoard extends Component {
    render() {
        return (
            <AddBoard_ { ...this.props } />
        )
    }
}

